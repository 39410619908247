import Button from '@mui/material/Button';

export const ButtonComponent = ({
  variant = 'contained',
  color = 'info',
  text,
  disabled = false,
  size = 'medium',
  startIcon = null,
  endIcon = null,
  style,
  onClick = () => {},
}) => {
  return (
    <Button
      onClick={onClick}
      variant={variant}
      disabled={disabled}
      size={size}
      startIcon={startIcon}
      endIcon={endIcon}
      color={color}
      style={style}
    >
      {text}
    </Button>
  );
};
