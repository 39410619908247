import axios from 'axios';
import { showNotification } from '../utils/notification';

export const getUnitesByStatus = async ({ status }) => {
  try {
    let formData = new FormData();

    formData.append('token', '-');
    formData.append('status', status);

    const { data } = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_BACKEND_API_HOST}/api/apartment/apartment_by_status.php`,
      data: formData,
      headers: {
        Accept: 'application/json',
        'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
      },
    });

    if (data?.status === 'success') {
      return data?.data;
    } else if (data?.status === 'error') {
      for (let i = 0; i < data?.message?.length; i++) {
        showNotification({ message: data?.message[i], type: 'error' });
      }
    } else {
      showNotification({ message: 'Something Went Wrong!', type: 'error' });
    }
  } catch (error) {
    showNotification({ message: 'Something Went Wrong!', type: 'error' });

    return null;
  }
};
