import { useEffect, useRef, useState } from 'react';

import { useReactToPrint } from 'react-to-print';

import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import { Typography } from '@mui/material';

import { StyledTableCell, TableRowCellStyle } from './TableStyles';
import { globalTheme } from '../../styles/themeStyle';
import { ButtonComponent } from '../../components/ButtonComponent';
import { getUnitesByStatus } from '../../services/apartment-service';
import { APARTMENT_STATUS, APARTMENT_STATUS_DROP_DOWN, UNIT_TYPES, VIEW_ARRAY } from '../../config/other';
import { LoadingComponent } from '../../components/LoadingComponent';
import { formatCurrency, roundNumber } from '../../utils/fomatter';
import { isNumber } from '../../utils/other';
import { SelectComponent } from '../../components/SelectComponent';
import { useParams } from 'react-router-dom';

const FloorTableRender = ({ level, data, viewType = false, grandTotalCalculator }) => {
  let totalPrice = 0;

  const getHoaNumber = (area, hoa) => {
    if (isNumber(area) && isNumber(hoa)) {
      return `$ ${formatCurrency(roundNumber(hoa * area, 2), 2)}`;
    }

    return '-';
  };

  const getPerSQFTPrice = (priceHistory, area) => {
    let startPerSqPrice = null;
    let currentPerSqPrice = null;
    let slotInitPrice = null;
    let slotCurrentPrice = null;

    if (priceHistory) {
      let priceHistoryArr = priceHistory.split(',');

      let priceHistoryLen = priceHistoryArr?.length;

      if (priceHistoryLen > 0) {
        startPerSqPrice = roundNumber(priceHistoryArr[0], 2);
        currentPerSqPrice = roundNumber(priceHistoryArr[priceHistoryLen - 1], 2);

        slotInitPrice = roundNumber(startPerSqPrice * area, 2);
        slotCurrentPrice = roundNumber(currentPerSqPrice * area, 2);
      }
    }

    return { startPerSqPrice, currentPerSqPrice, slotInitPrice, slotCurrentPrice };
  };

  return (
    <Grid container mb={5}>
      <Grid item xs={24} md={24} align="end">
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell
                  colSpan={viewType ? 11 : 8}
                  align="center"
                  style={{ padding: '5px', backgroundColor: 'rgb(230, 240, 250)' }}
                >
                  <Typography variant="p" component="p">
                    <b>Level {level - 1}</b>
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>

            <TableHead>
              <TableRow>
                <StyledTableCell theme={globalTheme} rowSpan={2}>
                  Unit
                </StyledTableCell>
                <StyledTableCell theme={globalTheme} rowSpan={2}>
                  SQFT
                </StyledTableCell>
                <StyledTableCell theme={globalTheme} rowSpan={2}>
                  Type
                </StyledTableCell>
                <StyledTableCell theme={globalTheme} rowSpan={2}>
                  View
                </StyledTableCell>
                <StyledTableCell theme={globalTheme} rowSpan={2}>
                  HOA
                </StyledTableCell>
                <StyledTableCell theme={globalTheme} colSpan={viewType ? 2 : 1} align="center">
                  Per SQFT Price
                </StyledTableCell>
                <StyledTableCell theme={globalTheme} colSpan={viewType ? 2 : 1} align="center">
                  Unit Price
                </StyledTableCell>
                <StyledTableCell theme={globalTheme} rowSpan={2} align="center"></StyledTableCell>
                {viewType && <StyledTableCell theme={globalTheme} rowSpan={2} align="center"></StyledTableCell>}
              </TableRow>
              {viewType && (
                <TableRow>
                  <StyledTableCell theme={globalTheme} align="center">
                    Initial
                  </StyledTableCell>
                  <StyledTableCell theme={globalTheme} align="center">
                    Current
                  </StyledTableCell>
                  <StyledTableCell theme={globalTheme} align="center">
                    Initial
                  </StyledTableCell>
                  <StyledTableCell theme={globalTheme} align="center">
                    Current
                  </StyledTableCell>
                </TableRow>
              )}
            </TableHead>

            <TableBody>
              {data.map((row, i) => {
                let { startPerSqPrice, currentPerSqPrice, slotInitPrice, slotCurrentPrice } = getPerSQFTPrice(
                  row?.priceHistory,
                  row?.area,
                  totalPrice
                );

                if (isNumber(slotCurrentPrice)) {
                  totalPrice = totalPrice + slotCurrentPrice;
                  grandTotalCalculator(slotCurrentPrice);
                }

                return (
                  <TableRow key={`${row.unitId}_${i}`}>
                    <TableCell sx={TableRowCellStyle} component="th" scope="row">
                      {row.unitId}
                    </TableCell>
                    <TableCell sx={TableRowCellStyle} align="start">
                      {row.area}
                    </TableCell>
                    <TableCell sx={TableRowCellStyle} align="start">
                      {row.type ? UNIT_TYPES[row.type] : '-'}
                    </TableCell>
                    <TableCell sx={TableRowCellStyle} align="start">
                      {row.view ? VIEW_ARRAY[row.view] : '-'}
                    </TableCell>
                    <TableCell sx={TableRowCellStyle} align="right">
                      {getHoaNumber(row.area, row.hoa)}
                    </TableCell>
                    {viewType && (
                      <TableCell sx={TableRowCellStyle} align="right">
                        {startPerSqPrice ? `$ ${formatCurrency(startPerSqPrice, 2)}` : '-'}
                      </TableCell>
                    )}
                    <TableCell sx={TableRowCellStyle} align="right">
                      {currentPerSqPrice ? `$ ${formatCurrency(currentPerSqPrice, 2)}` : '-'}
                    </TableCell>
                    {viewType && (
                      <TableCell sx={TableRowCellStyle} align="right">
                        {slotInitPrice ? `$ ${formatCurrency(slotInitPrice, 2)}` : '-'}
                      </TableCell>
                    )}
                    <TableCell sx={TableRowCellStyle} align="right">
                      {slotCurrentPrice ? `$ ${formatCurrency(slotCurrentPrice, 2)}` : '-'}
                    </TableCell>
                    <TableCell sx={TableRowCellStyle} align="center"></TableCell>
                    {viewType && <TableCell sx={TableRowCellStyle} align="center"></TableCell>}
                  </TableRow>
                );
              })}
            </TableBody>

            <TableHead>
              <TableRow>
                <TableCell colSpan={viewType ? 8 : 6} align="right">
                  Total:
                </TableCell>
                <TableCell colSpan={1} align="right">
                  {`$ ${formatCurrency(roundNumber(totalPrice, 2), 2)}`}
                </TableCell>
              </TableRow>
            </TableHead>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export const ApartmentListLayout = () => {
  let grandTotal = 0;

  let { statusId } = useParams();
  const componentRef = useRef();

  const [isLoading, setIsLoading] = useState(false);
  const [unitList, setUnitList] = useState({});
  const [status, setStatus] = useState(statusId ? statusId : APARTMENT_STATUS.UNAVAILABLE);
  const [viewType, setViewType] = useState(false);

  useEffect(() => {
    (async function () {
      setIsLoading(true);

      if (status) {
        const data = await getUnitesByStatus({ status });

        if (data) {
          setUnitList(data);
        }
      }

      setIsLoading(false);
    })();
  }, [status]);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const grandTotalCalculator = (value) => {
    if (value) {
      grandTotal = grandTotal + value;
    }
  };

  return (
    <Grid container p={1} mt={8}>
      <Grid item xs={12} p={1}>
        <Grid container>
          <Grid item xs={12} md={5}></Grid>
          <Grid item xs={12} md={4}>
            <SelectComponent
              label={'Unit Status'}
              value={status}
              values={APARTMENT_STATUS_DROP_DOWN}
              onChange={(e) => {
                setStatus(e.target.value);
              }}
              disabled={isLoading}
            />
          </Grid>

          <Grid item xs={12} md={3} align="end">
            <ButtonComponent
              text={viewType ? 'ADMIN VIEW' : 'AGENT VIEW'}
              onClick={() => {
                setViewType(!viewType);
              }}
              style={{ marginRight: '5px' }}
              color="warning"
            />
            <ButtonComponent
              text={'Download PDF'}
              startIcon={<LocalPrintshopIcon />}
              onClick={() => {
                handlePrint();
              }}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} p={1} ref={componentRef}>
        <Grid item xs={12} p={1}>
          <Grid container>
            <Grid item xs={12} md={9}>
              <Typography variant="h5" component="h5" style={{ textTransform: 'uppercase' }}>
                {status} Units
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} p={1}>
          <Grid container>
            {isLoading ? (
              <LoadingComponent />
            ) : (
              <>
                {Object.keys(unitList)?.map((floorId) => {
                  let unitsByFloor = unitList[floorId];

                  return FloorTableRender({ level: floorId, data: unitsByFloor, viewType, grandTotalCalculator });
                })}
                <Grid item xs={24} md={24} className="blue-background" mb={1}>
                  <Typography variant="p" component="p" mb={1} style={{ textAlign: 'right' }}>
                    <b>Grand Total: {`$ ${formatCurrency(roundNumber(grandTotal, 2), 2)}`}</b>
                  </Typography>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
