import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

export const SelectComponent = ({
  autoWidth,
  classes,
  error,
  defaultOpen,
  defaultValue,
  displayEmpty,
  IconComponent,
  input,
  inputProps,
  label,
  MenuProps,
  multiple,
  native,
  onChange,
  onClose,
  onOpen,
  open,
  renderValue,
  SelectDisplayProps,
  sx,
  value,
  variant,
  values,
  className,
  size = 'small',
  formSX,
  disabled,
  required,
  defaultPlaceholder,
}) => {
  return (
    <FormControl size={size} sx={formSX} error={error} fullWidth={true}>
      <InputLabel id="demo-simple-select-autowidth-label">
        {label}
        {required ? ' *' : ''}
      </InputLabel>
      <Select
        labelId="demo-simple-select-autowidth-label"
        id="demo-simple-select-autowidth"
        autoWidth={autoWidth}
        classes={classes}
        error={error}
        defaultOpen={defaultOpen}
        defaultValue={defaultValue}
        displayEmpty={displayEmpty}
        IconComponent={IconComponent}
        input={input}
        required={required}
        inputProps={inputProps}
        label={label}
        MenuProps={MenuProps}
        multiple={multiple}
        native={native}
        onChange={onChange}
        onClose={onClose}
        onOpen={onOpen}
        open={open}
        renderValue={renderValue}
        SelectDisplayProps={SelectDisplayProps}
        style={sx}
        value={value}
        variant={variant}
        className={className ? className : 'select_basic align-left'}
        size={size}
        disabled={disabled}
      >
        {defaultPlaceholder && <MenuItem value="">Please Select ...</MenuItem>}
        {values?.length > 0 &&
          values?.map((item) => {
            return (
              <MenuItem value={item.id} key={item.id}>
                {item.name}
              </MenuItem>
            );
          })}
      </Select>
    </FormControl>
  );
};
