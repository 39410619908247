import { Box, Grid, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

export const LoadingComponent = ({ text = 'Loading...' }) => {
  return (
    <Grid container align="center" p={5}>
      <Grid item sx={{ margin: 'auto' }}>
        <Box>
          <CircularProgress />
          <Typography variant="p" component="p" mt={2}>
            {text}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};
