export const VIEW_TYPES = {
  ADMIN: 'ADMIN',
  AGENT: 'AGENT',
};

export const APARTMENT_STATUS = {
  UNAVAILABLE: 'UNAVAILABLE',
  AVAILABLE: 'AVAILABLE',
  CONTRACT: 'CONTRACT',
  HOLD: 'HOLD',
  RESERVED: 'RESERVED',
  SOLD: 'SOLD',
};

export const APARTMENT_STATUS_DROP_DOWN = [
  { id: 'UNAVAILABLE', name: 'Unavailable' },
  { id: 'AVAILABLE', name: 'Available' },
  { id: 'CONTRACT', name: 'Contract' },
  { id: 'HOLD', name: 'Hold' },
  { id: 'RESERVED', name: 'Reserved' },
  { id: 'SOLD', name: 'Sold' },
];

export const UNIT_TYPES = {
  STUDIO_420: 'STUDIO 420 SQFT',
  '1BD_1BTH_459': '1BD / 1BTH - 459 SQFT',
  STUDIO_420_TERRACE: 'STUDIO  420 SQFT TERRACE',
  STUDIO_387_TERRACE: 'STUDIO  387 SQFT TERRACE',
  '2BD_2BTH_774_TERRACE': '2BD / 2BTH - 774 SQFT TERRACE',
  '2BD_2BTH_774': '2BD / 2BTH - 774 SQFT',
  '3BD_2_5BTH_1233': '3BD 2.5BTH 1233 SQFT',
  '3BD_DEN_2BTH_2HB_1702': '3BD / DEN  2 BTH 2 HB 1702 SQFT',
  STUDIO_387: 'STUDIO 387 SQFT',
  '2BD_2BTH_849_TERRACE': '2BD / 2BTH - 849 SQFT TERRACE',
  '4BD_2BTH_2HB_1702': '4BD 2 BTH 2 HB 1702 SQFT',
  '2BD_2BTH_849': '2BD / 2BTH - 849 SQFT',
  '2BD_2BTH_888': '2BD / 2BTH - 888 SQFT',
  '3BD_2BTH_2HB_1676': '3BD 2 BTH 2 HB 1676 SQFT',
};

export const VIEW_ARRAY = {
  NORTH: 'North',
  SOUTH: 'South',
  'NORTH-SOUTH': 'North/South',
};
