import React, { Suspense } from 'react';
import { BrowserRouter, Routes, Route, Outlet } from 'react-router-dom';
import { LoadingComponent } from './components/LoadingComponent';
import TopBarComponent from './components/TopBarComponent';

// import { FooterComponent } from './components/FooterComponent';

import { ApartmentListLayout } from './layout/apartment/ApartmentListLayout';

function App() {
  return (
    <BrowserRouter>
      <Suspense fallback={<LoadingComponent />}>
        <Routes>
          <Route
            path="/"
            element={
              <>
                <TopBarComponent />
                <Outlet />
                {/* <FooterComponent /> */}
              </>
            }
          >
            <Route path="apartment/:statusId" element={<ApartmentListLayout />}></Route>
            <Route path="*" exact={true} component={<>PAGE NOT FOUND</>} />
          </Route>
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
