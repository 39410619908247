export const roundNumber = (value, decimal_places = 0) => {
  const places = 10 ** decimal_places;
  const res = Math.round(value * places) / places;
  return res;
};

export const formatCurrency = (value, digits = 0) => {
  if (value === null || value === undefined || value === '') {
    return value;
  } else if (!isNaN(value && value > 0)) {
    const numberFormatter = Intl.NumberFormat('en-US', { minimumFractionDigits: digits });

    return numberFormatter.format(value);
  } else {
    return value;
  }
};
