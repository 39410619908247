import { TableCell, tableCellClasses } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    border: '1px solid white',
    padding: '5px',
    // borderTopLeftRadius: '5px',
    // borderTopRightRadius: '5px',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export const TableRowCellStyle = {
  borderRight: '1px solid rgba(224, 224, 224, 1)',
  paddingTop: '8px',
  paddingBottom: '8px',
};
